import React from "react";
import {
    Grid,
    GridList,
    Paper,
    Container,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { withTheme } from "@material-ui/core/styles";
import { Element } from "react-scroll";
import backgroundImage from "../static/images/stock/AdobeStock_81044445_Preview.jpg";
import researchImage from "../static/images/stock/AdobeStock_242804468.jpeg";
import marketImage from "../static/images/stock/AdobeStock_205176900.jpeg";
const useStyles = makeStyles((theme) => ({
    element: {},
    servicesContainer: {
        // height: "100vh",
        minHeight: "100vh",
        width: "100vw",
        marginTop: 0,
        // maxHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        paddingBottom: '3em',
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        // },
    },
    servicesHeading: {
        [theme.breakpoints.down("sm")]: {
            // height: "3rem",
            flex: 1,
        },
    },
    servicesText: {
        fontSize: "4rem",
        fontWeight: "bold",
        color: theme.palette.primary.headlineText,
        display: "table",
        margin: "6vh auto",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem",
            marginTop: "2em",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.4rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            marginLeft: "1rem",
            marginBottom: 0,
        },
        "& ul": {
            //   listStyleType: "none"
        },
    },
    serviceListContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            flex: 5,
        },
    },
    element: {
        margin: "0 auto",
    },
    headingText: {
        fontSize: "1rem",
        fontWeight: "bold",
        color: theme.palette.primary.headlineText,
        display: "block",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem",
            marginTop: "2vh",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.4rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
        },
    },

    marketContainer: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    marketBanner: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${marketImage})`,
        height: "40vh",
        flex: 4.2,
        [theme.breakpoints.down("md")]: {
            backgroundPosition: "0px 0px",
        },
        [theme.breakpoints.down("sm")]: {
            // backgroundPosition: "0px -10vh",
            // flex: 1,
            // height: "20vh",
            display: "none",
        },
    },
    marketing: {
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        flexDirection: "column",
        flex: 4,
    },
    researchContainer: {
        display: "flex",
        flexDirection: "row-reverse",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    researchBanner: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${researchImage})`,
        height: "40vh",
        flex: 4.2,
        [theme.breakpoints.down("sm")]: {
            // height: "20vh",
            display: "none",
        },
    },
    research: {
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        flexDirection: "column",
        flex: 4,
    },
    profileText: {
        fontSize: "3rem",
        fontFamily: "Crimson Text",
        marginLeft: "1vw",
        textAlign: "justify",
        "& ul": {
            listStylePosition: "outside",
            paddingLeft: 15,
            margin: "1em",
        },
        [theme.breakpoints.down("xl")]: {
            fontSize: "2rem",
            marginTop: "2vh",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".8rem",
        },
    },
    emphText: {
        fontWeight: 400,
    },
    list: {
        "& ul": {
            listStylePosition: "outside",
            // paddingLeft: 15,
        },
    },
    subHeadingText: {
        fontSize: "3.5rem",
        fontWeight: "bold",
        color: theme.palette.primary.headlineText,
        display: "block",
        alignSelf: "left",
        marginLeft: "1vw",
        [theme.breakpoints.down("xl")]: {
            fontSize: "2rem",
            marginTop: "2vh",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.2rem",
            marginLeft: "1rem",
            marginTop: "1rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            marginLeft: "2rem",
            marginTop: "1rem",
        },
    },
}));
const Services = (props) => {
    const classes = useStyles();

    return (
        <Element name="services" className={classes.element}>
            <div className={classes.servicesContainer}>
                {/* <Grid container> */}
                <div className={classes.servicesHeading}>
                    <>
                        {/* <span className={classes.headingText}>Services</span> */}
                        <span className={classes.servicesText}>
                            Our services are subdivided into two major categories:{" "}
                        </span>
                    </>
                </div>
                <div className={classes.serviceListContainer}>
                    <Grid item sm={6} md={12}>
                        <div className={classes.marketContainer}>
                            <div className={classes.marketBanner}></div>
                            <div className={classes.marketing}>
                                <span className={classes.subHeadingText}>
                                    Market Access and Reimbursement
                                </span>
                                <span className={classes.profileText}>
                                    <ul>
                                        <li>
                                            Development of market access and reimbursement strategies
                                            for new products
                                        </li>
                                        <li>Product pricing and budget impact analyses</li>
                                        <li>
                                            Market research for the major global pharmaceutical
                                            markets
                                        </li>
                                        <li>
                                            Focus groups/advisory committees involving key
                                            stakeholders
                                        </li>
                                        <li>Sales force training and education </li>
                                        <li>The development of value dossiers for new products</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </Grid>

                    <Grid item sm={6} md={12}>
                        <div className={classes.researchContainer}>
                            <div className={classes.researchBanner}></div>
                            <div className={classes.research}>
                                <span className={classes.subHeadingText}>Research Based</span>
                                <span className={classes.profileText}>
                                    <ul>
                                        <li>
                                            Health economic evaluations of drug products, medical
                                            devices or health services
                                        </li>
                                        <li>Clinical drug development and clinical trial design</li>
                                        <li>Power analysis and sample size estimation</li>
                                        <li>
                                            Measurement of quality of life and patient reported
                                            outcomes
                                        </li>
                                        <li>
                                            Meta analysis, meta regression analysis and Bayesian
                                            network meta analysis
                                        </li>
                                        <li>Value based product pricing</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </Grid>
                </div>
                {/* </Grid> */}
            </div>
        </Element>
    );
};
export default withTheme(Services);
