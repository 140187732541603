import React from "react";
import {
    Grid,
    GridList,
    Paper,
    Container,
    Typography,
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { Element } from "react-scroll";
import { makeStyles } from "@material-ui/core";
import backgroundImage from "../static/images/stock/AdobeStock_247574715.jpeg";
// import sideImage from "../static/images/stock/sideline_pics/profile.png";
import sideImage from "../static/images/stock/sideline_pics/AdobeStock_84021831.jpeg";

const useStyles = makeStyles((theme) => ({
    profileContainer: {
        minHeight: "100vh",
        width: "100vw",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingLeft: 0,
        overflow: "hidden",
    },
    mainGrid: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        marginLeft: "-5vw",
    },
    element: {
        // paddingTop: 20,
        paddingLeft: "15vw",
        [theme.breakpoints.down("lg")]: {
            // paddingLeft: "0rem",
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "2rem",
        },
    },
    sideImageContainer: {
        marginTop: 0,
        overflow: "hidden",
        display: "none",
        minHeight: "100vh",
        [theme.breakpoints.up("md")]: {
            display: "block",
        },
        [theme.breakpoints.up("sm")]: {
            background: `url(${sideImage})`,
            backgroundSize: "cover",
        },
    },
    sideImageInnerContainer: {
        padding: 0,
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block",
        },
    },
    profileText: {
        fontWeight: 300,
        textAlign: "justify",
        fontSize: "3rem",
        marginTop: "2vh",
        display: "block",
        fontFamily: "Crimson Text",
        lineHeight: "3.5vh",
        "& ul": {
            listStylePosition: "outside",
            paddingLeft: 25,
        },
        [theme.breakpoints.down("xl")]: {
            fontSize: "2rem",
            marginTop: "2vh",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".85rem",
            lineHeight: '1rem'
        },
    },
    headingText: {
        fontSize: "4rem",
        fontWeight: "bold",
        color: theme.palette.primary.headlineText,
        display: "block",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem",
            marginTop: "2vh",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "2rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1em",
            marginTop: "3em",
        },
        // marginTop: "15vh",

        // [theme.breakpoints.up("xs")]: {
        //   fontSize: "1.3em",
        // },
        // [theme.breakpoints.up("lg")]: {
        //   fontSize: "1.8em",
        // },
    },
    subHeadingText: {
        fontSize: "3.5rem",
        display: "block",
        color: theme.palette.primary.headlineText,
        marginTop: "3vh",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem",
            marginTop: "2vh",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.4rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".9em",
        },
        // [theme.breakpoints.up("xs")]: {
        //   fontSize: "1.2em",
        // },
        // [theme.breakpoints.up("lg")]: {
        //   fontSize: "1.6em",
        // },
    },
    emphText: {
        fontWeight: 400,
    },
}));
const Profile = (props) => {
    const classes = useStyles();
    return (
        <Element name="profile">
            <div className={classes.profileContainer}>
                <Grid container>
                    <Grid item md={2} className={classes.sideImageContainer}>
                        {/* <Container className={classes.sideImageInnerContainer}>
             
            </Container> */}
                    </Grid>
                    <Grid item md={10} xs={12} className={classes.mainGrid}>
                        <>
                            <Element className={classes.element}>
                                <span className={classes.headingText}>
                                    Consultancy in Health Economics, Biostatistics and Market
                                    Access
                                </span>
                                <span className={classes.subHeadingText}>
                                    Principal Consultant
                                </span>
                                <span className={classes.profileText}>
                                    George Dranitsaris, B.Pharm, MSc, PhD, FCSHP
                                </span>
                                <span className={classes.profileText} component={"span"}>
                                    <ul>
                                        <li>
                                            Expertise in health outcomes research and clinical trial
                                            design
                                        </li>
                                        <li>
                                            Graduate training in biostatistics, health economics and
                                            clinical epidemiology
                                        </li>
                                        <li>Licensed Ontario pharmacist</li>
                                        <li>
                                            <span>Past President</span>: Canadian Association of
                                            Pharmacists in Oncology
                                        </li>
                                        <li>
                                            <span>Editorial Board Member</span>: Oncogene,
                                            Pharmacoeconomics – Open, Journal of Oncology Pharmacy
                                            Practice
                                        </li>
                                    </ul>
                                </span>
                                <span className={classes.subHeadingText}>Experience</span>
                                <span className={classes.profileText} component={"span"}>
                                    <ul>
                                        <li>
                                            Consultant in Health Economics and Biostatistics: 1992 -
                                            present
                                        </li>
                                        <li>
                                            Health Policy Research Consultant, Cancer Care Ontario:
                                            2002 - 2006
                                        </li>
                                        <li>
                                            Director of the Outcomes Research Unit, Princess Margaret
                                            Hospital: 1992 – 2000
                                        </li>
                                        <li>
                                            Graduate faculty, College of Pharmacy, Florida A&M
                                            University: 2012-present
                                        </li>
                                    </ul>
                                </span>
                            </Element>
                        </>
                    </Grid>
                </Grid>
            </div>
        </Element>
    );
};
export default withTheme(Profile);
