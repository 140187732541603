import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import BuildIcon from "@material-ui/icons/Build";
import ContactsIcon from "@material-ui/icons/Contacts";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CameraIcon from "@material-ui/icons/Camera";
import Profile from "./Profile";
import Services from "./Services";
import Home from "./Home";
import Clients from "./Clients";
import Focus from "./Focus";
import Publications from "./Publications";
import Presentations from "./Presentations";
import Contact from "./Contact";
import { Link as ScrollerLink } from "react-scroll";
import aug_logo_new from "../static/images/aug_logo_new.png";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Montserrat",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.primary.dark,
    color: theme.palette.text.light,
    height: "5vh",
    justifyContent: "center",
    // paddingTop: "1vh",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth + 50,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth + 50,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  container: {
    // paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    marginRight: theme.spacing(0),
    height: "auto",
    position: "relative",
    top: "5vh",
  },
  splashContainer: {
    // backgroundImage: 'url(' + pharma + ')',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "auto",
    minHeight: 1000,
    width: "100%",
    marginTop: -10,
  },
  welcomeText: {
    color: theme.palette.text.light,
    position: "relative",
    top: 100,
    left: 0,
    fontSize: "4em",
  },
  containerText: {
    fontSize: "1.2em",
    width: 600,
    textAlign: "justify",
    margin: "0 auto",
    // fontFamily: theme.span.fontFamily,
  },
  arrow: {
    color: theme.palette.text.light,
    position: "relative",
    top: 100,
    left: 0,
    fontSize: "8em",
  },
  downIcon: {
    width: 100,
  },
  largeIcon: {
    fontSize: "6em",
  },
  element: {
    position: "relative",
    top: 800,
    paddingTop: 100,
  },

  content: {
    flexGrow: 1,
    // paddingTop: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -(drawerWidth + 50),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  pinnedNav: {
    zIndex: 50,
    width: "39px",
    height: "auto",
    minWidth: "12px",
    minHeight: "60px",
    position: "sticky",
    left: "90%",
    top: "40%",
    color: "#ccc",
    "& ul": {
      listStyleType: "none",
      "& li": {
        marginTop: "10px",
      },
      "& .navCircle": {
        cursor: "pointer",
        flex: "0 0 auto",
        transition: "all 0.2s ease-in-out",
        fill: "rgba(255,255,255,0)",
        stroke: "rgba(201, 44, 94, 1)",
        strokeWidth: "2px",
      },
    },
    "& svg:hover": {
      fill: "red",
    },
  },
  title: {
    fontSize: ".9vw",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8em",
    },
  },
  topNav: {
    position: "absolute",
    right: "20%",
    color: theme.palette.text.light,
    whiteSpace: "nowrap",
    width: "50%",
    userSelect: "none",
    cursor: "pointer",
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
    "& p": {
      display: "inline-block",
      marginRight: 20,
    },
    "& a": {
      color: theme.palette.text.light,
      textDecoration: "none",
      fontSize: "1vw",
      marginRight: "1.5vw",
      [theme.breakpoints.down("lg")]: {
        fontSize: "1rem",
      },
    },
  },
  topNavLink: {
    color: theme.palette.secondary.light,
  },
  activeLink: {
    color: theme.palette.primary.light + " !important",
  },
  logo: {
    width: "3vw",
    [theme.breakpoints.down("sm")]: {
      width: 40,
    },
  },
}));

function Dashboard(props) {
  const theme = useTheme();
  console.log(theme);
  const classes = useStyles();
  // console.log(theme.palette.primary.dark)
  const [open, setOpen] = React.useState(false);
  const [classesOpen, setClassesOpen] = React.useState(false);
  const [curScroll, setCurScroll] = React.useState(0);

  function handleDrawerOpen() {
    setOpen(true);
  }
  function calcDuration(test) {
    console.log("argh" + test);
    return 500;
  }
  function handleDrawerClose() {
    setOpen(false);
  }

  function handleClick() {
    setOpen(!open);
  }
  const scroller = (target) => {
    try {
      scroller.scrollTo(target, {
        duration: 50,
        delay: 0,
        smooth: "linear",
      });
    } catch (e) {
      // console.log(e)
    }
  };
  return (
    <Router>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          color="primary"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <a href="/">
              <img src={aug_logo_new} className={classes.logo} />
            </a>
            <span className={classes.title}>
              Augmentium Pharma Consulting Inc.
            </span>
            <nav className={classes.topNav}>
              <span onClick={scroller}>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="home"
                  spy={true}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(0 - curScroll);
                    setCurScroll(0);
                    return duration / 1.5;
                  }}
                  offset={0}
                >
                  Home
                </ScrollerLink>
              </span>
              <span onClick={scroller}>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="profile"
                  spy={true}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(550 - curScroll);
                    setCurScroll(550);
                    return duration / 1.5;
                  }}
                  offset={0}
                >
                  Profile
                </ScrollerLink>
              </span>
              <span onClick={scroller}>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="services"
                  spy={true}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(1100 - curScroll);
                    setCurScroll(1100);
                    return duration / 1.5;
                  }}
                  offset={0}
                >
                  Services
                </ScrollerLink>
              </span>
              <span onClick={scroller}>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="clients"
                  spy={true}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(1676 - curScroll);
                    setCurScroll(1676);
                    return duration / 1.5;
                  }}
                  offset={0}
                >
                  Clients
                </ScrollerLink>
              </span>
              <span onClick={scroller}>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="focus"
                  spy={true}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(2225 - curScroll);
                    setCurScroll(2225);
                    return duration / 1.5;
                  }}
                  offset={0}
                >
                  Therapeutic Focus
                </ScrollerLink>
              </span>
              <span onClick={scroller}>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="publications"
                  spy={true}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(2777 - curScroll);
                    setCurScroll(2777);
                    return duration / 1.5;
                  }}
                  offset={0}
                >
                  Publications
                </ScrollerLink>
              </span>
              <span onClick={scroller}>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="presentations"
                  spy={true}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(3660 - curScroll);
                    setCurScroll(3660);
                    return duration / 1.5;
                  }}
                  offset={0}
                >
                  Professional Presentations
                </ScrollerLink>
              </span>
              <span onClick={scroller}>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="contact"
                  spy={true}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(4500 - curScroll);
                    setCurScroll(4500);
                    return duration / 1.5;
                  }}
                  offset={0}
                >
                  Contact Us
                </ScrollerLink>
              </span>
            </nav>

            {/* <Settings /> */}
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <List>
            <ListItem>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <span>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="home"
                  spy={true}
                  onClick={handleClick}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(0 - curScroll);
                    setCurScroll(0);
                    return duration / 2;
                  }}
                  offset={0}
                >
                  Home
                </ScrollerLink>
              </span>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <span>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="profile"
                  spy={true}
                  onClick={handleClick}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(1256 - curScroll);
                    setCurScroll(1256);
                    return duration / 2;
                  }}
                  offset={0}
                >
                  Profile
                </ScrollerLink>
              </span>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <span>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="services"
                  spy={true}
                  onClick={handleClick}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(1930 - curScroll);
                    setCurScroll(1930);
                    return duration / 2;
                  }}
                  offset={0}
                >
                  Services Offered
                </ScrollerLink>
              </span>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ContactsIcon />
              </ListItemIcon>
              <span>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="clients"
                  spy={true}
                  onClick={handleClick}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(3191 - curScroll);
                    setCurScroll(3191);
                    return duration / 2;
                  }}
                  offset={0}
                >
                  Clients
                </ScrollerLink>
              </span>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CameraIcon />
              </ListItemIcon>
              <span>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="focus"
                  spy={true}
                  onClick={handleClick}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(4315 - curScroll);
                    setCurScroll(4315);
                    return duration / 2;
                  }}
                  offset={0}
                >
                  Therapeutic Focus
                </ScrollerLink>
              </span>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <span>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="publications"
                  spy={true}
                  onClick={handleClick}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(5002 - curScroll);
                    setCurScroll(5002);
                    return duration / 2;
                  }}
                  offset={0}
                >
                  Publications
                </ScrollerLink>
              </span>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <span>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="presentations"
                  spy={true}
                  onClick={handleClick}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(6350 - curScroll);
                    setCurScroll(6350);
                    return duration / 2;
                  }}
                  offset={-20}
                >
                  Professional Presentations
                </ScrollerLink>
              </span>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <span>
                <ScrollerLink
                  activeClass={classes.activeLink}
                  className="topNavLink"
                  to="contact"
                  spy={true}
                  onClick={handleClick}
                  smooth={"easeInOutCubic"}
                  duration={() => {
                    let duration = Math.abs(7817 - curScroll);
                    setCurScroll(7817);
                    return duration / 2;
                  }}
                  offset={-20}
                >
                  Contact Us
                </ScrollerLink>
              </span>
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={false} className={classes.container}>
            <Home />
            <Profile />
            <Services />
            <Clients />
            <Focus />
            <Publications />
            <Presentations />
            <Contact />
          </Container>
        </main>
      </div>
    </Router>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
