import React from "react";
import { Grid, GridList, Paper, Container } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Astra from "../static/images/cards/AstraZeneca.jpg";
import Bayer from "../static/images/cards/Bayer.jpg";
import ClientData from "../utils/clientData";
import { Element } from "react-scroll";
// import backgroundImage from '../static/images/stock/pharmaceutical_light.jpg';
import backgroundImage from "../static/images/stock/unsplash.jpg";
import { withTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    // Paper: { width: "50%", margin: "0 auto", top: 20, position: "relative" },
    clientCardContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: "4vh",
        width: "80%",
        marginLeft: "10%",
        // marginBottom: '2em',
        [theme.breakpoints.down("lg")]: {
            marginLeft: "0",
            width: "100%",
            marginTop: ".2rem",
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: "5%",
            width: "90%",
            marginTop: ".2rem",
        },
        // paddingLeft: "5vw",
    },
    card: {
        width: "12vw",
        height: "5vw",
        marginRight: "1vw",
        marginBottom: "1vw",
        borderRadius: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
        border: "1px solid grey",
        boxShadow: "1rem 1rem 1rem #ccc",
        [theme.breakpoints.down("lg")]: {
            width: "20vw",
            height: "12vh",
        },
        [theme.breakpoints.down("sm")]: {
            width: "27vw",
            height: "15vw",
            borderRadius: ".3rem",
            marginRight: ".5rem",
            marginBottom: ".5rem",
        },
    },
    media: {
        objectFit: "contain",
        width: "65%",
        maxHeight: "4vw",
        [theme.breakpoints.down("lg")]: {
            width: "90%",
            maxHeight: "10vh",
        },
        [theme.breakpoints.down("sm")]: {
            width: "90%",
            maxHeight: "14vw",
        },
    },
    cardContent: {
        display: "block",
        [theme.breakpoints.up("lg")]: {
            fontSize: "1rem",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.4rem",
        },
        // position: 'absolute',
        // top: '20px',
    },
    clientContainer: {
        height: "auto",
        minHeight: "100vh",
        width: "100vw",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${backgroundImage})`,
        paddingBottom: '4em',
        display: "flex",
        flexDirection: "column",
    },
    headingText: {
        fontSize: "4rem",
        fontWeight: "bold",
        color: theme.palette.primary.headlineText,
        display: "block",
        margin: "5vw 0 0 10vw",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.4rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            marginTop: "3rem",
            marginLeft: "1rem",
        },
        // textAlign: "left",
        // marginBottom: "20px !important",
        // marginTop: "30px !important",
    },
    clientText: {
        fontSize: "3rem",
        fontFamily: "Crimson Text",
        marginTop: "2vh",
        marginLeft: "10vw",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem",
            margin: "1em 4em",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1rem",
            margin: "0.5rem 5rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".85rem", lineHeight: '1rem',
            textAlign: "justify",
            margin: ".5rem 1rem",
            // marginTop: "3rem",
        },
        // marginBottom: 30,
    },
    headingGrid: {
        textAlign: "left",
    },
}));
const Clients = (props) => {
    const classes = useStyles();
    const clientData = { ...ClientData.clientData };

    return (
        <Element name="clients" className={classes.element}>
            <div className={classes.clientContainer}>
                <>
                    <span className={classes.headingText}>Clients</span>
                    <span className={classes.clientText}>
                        Our clients come from a diverse group of pharmaceutical companies
                        and public organizations. They have always challenged us with
                        focused needs from multiple perspectives.
                    </span>
                    <div className={classes.clientCardContainer}>
                        {
                            // console.log(clientData)
                            clientData.clients.map((client) => {
                                let clientImage = require("../static/images/cards/" +
                                    client.image);
                                return (
                                    // <Grid item xs={4} md={2} key={client.name}>
                                    <div className={classes.card} key={client.name}>
                                        {/* <CardActionArea> */}
                                        <img
                                            className={classes.media}
                                            src={clientImage}
                                            title={client.name}
                                            component="img"
                                        />
                                        {/* <CardContent className={classes.cardContent}>
                            {client.cardContent}
                         </CardContent> */}
                                        {/* </CardActionArea> */}
                                    </div>
                                    // {/* </Link> */}
                                    // </Grid>
                                );
                            })
                        }
                    </div>
                    <span className={classes.headingText}>Public Organizations</span>
                    <div className={classes.clientCardContainer}>
                        {
                            // console.log(clientData)
                            clientData.public.map((client) => {
                                let clientImage = require("../static/images/cards/" +
                                    client.image);
                                return (
                                    <div className={classes.card} key={client.name}>
                                        <img
                                            className={classes.media}
                                            src={clientImage}
                                            title={client.name}
                                            component="img"
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                </>
            </div>
        </Element>
    );
};
export default withTheme(Clients);
