import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ClientData from "../utils/clientData";
import { Element } from "react-scroll";
import { withTheme } from "@material-ui/core/styles";
// import backgroundImage from '../static/images/stock/therapeutic.jpg'
import backgroundImage from "../static/images/stock/AdobeStock_205935548.jpg";

// import sideImage from '../static/images/stock/sideline_pics/stock-photo-medicine-capsule-pills-and-drugs-accessibility-pharmacy-1231980235.png';
import sideImage from "../static/images/stock/sideline_pics/AdobeStock_101216290.jpg";

const useStyles = makeStyles((theme) => ({
    element: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    focusContainer: {
        minHeight: "100vh",
        // maxHeight: "100vh",
        overflow: "hidden",
        width: "50vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: '3em',
        [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginLeft: "15px",
        },
    },
    sideImageContainer: {
        overflow: "hidden",
        minHeight: "95vh",
        marginRight: 0,
        marginLeft: 0,
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block",
            background: `url(${sideImage})`,
            backgroundSize: "cover",
        },
    },

    list: {
        marginTop: "1vh",
        listStylePosition: "inside",
        fontSize: "3rem",
        fontFamily: "Crimson Text",
        fontWeight: 300,
        textAlign: "justify",
        marginBottom: "2vh",
        position: "relative",
        [theme.breakpoints.down("xl")]: {
            fontSize: "2rem",
            // marginTop: "3rem",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1rem",
            // marginTop: "3rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".85rem", lineHeight: "1rem"
            // marginTop: "3rem",
        },
    },
    headingText: {
        paddingTop: 35,
        fontSize: "4rem",
        fontWeight: "bold",
        color: theme.palette.primary.headlineText,
        textAlign: "left",
        display: "block",
        marginBottom: "2vh",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem",
            marginTop: "3rem",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "2rem",
            // marginTop: "3rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.4rem",
            // marginTop: "3rem",
        },
    },
    categoryHeadingText: {
        fontSize: "3.5rem",
        fontWeight: "bold",
        color: theme.palette.primary.headlineText,
        display: "block",
        [theme.breakpoints.down("xl")]: {
            fontSize: "2rem",
            // marginTop: "3rem",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1rem",
            // marginTop: "3rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            // marginTop: "3rem",
        },
        // marginBottom: "0vh",
        // paddingTop: 20,
    },
    focusText: {
        fontSize: "3rem",
        fontFamily: "Crimson Text",
        fontWeight: 300,
        textAlign: "justify",
        marginBottom: "2vh",
        [theme.breakpoints.down("xl")]: {
            fontSize: "2rem",
            // marginTop: "3rem",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1rem",
            // marginTop: "3rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".85rem",
            lineHeight: "1em"
            // marginTop: "3rem",
        },
    },
}));
const Focus = (props) => {
    //   console.log(props.theme.elements);
    const classes = useStyles();
    const clientData = { ...ClientData.clientData };
    return (
        <Element name="focus" className={classes.element}>
            <Grid container spacing={0}>
                <Grid item md={3} className={classes.sideImageContainer}></Grid>
                <Grid item md={9} xs={12}>
                    <Container maxWidth={false} className={classes.focusContainer}>
                        <span className={classes.headingText}>Therapeutic Focus</span>
                        <span className={classes.focusText}>
                            Our belief is that quality service can only be offered to our
                            clients if we specialize in key therapeutic areas. By taking this
                            approach, we are always up-to-date with new clinical developments.
                        </span>
                        <span className={classes.categoryHeadingText}>Primary</span>
                        {/* <span className={classes.focusText} component={"span"}> */}
                        <ul className={classes.list}>
                            <li>Oncology & Hematology</li>
                            <li>Immunology</li>
                            <li>Rare Diseases</li>
                            <li>Blood Products and Biologicals</li>
                            <li>Infectious Diseases</li>
                        </ul>
                        {/* </span> */}
                        <span className={classes.categoryHeadingText}>Secondary</span>
                        {/* <span className={classes.focusText} component={"span"}> */}
                        <ul className={classes.list}>
                            <li>Diabetes</li>
                            <li>Cardiology</li>
                            <li>Antithrombotics</li>
                            <li>Respiratory Diseases</li>
                            <li>Neurology</li>
                        </ul>
                        {/* </span> */}
                    </Container>
                </Grid>
            </Grid>
        </Element>
    );
};
export default withTheme(Focus);
