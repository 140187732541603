const documents = {
  publications: [
    {
      title:
        "A randomized trial comparing risk model guided antiemetic prophylaxis to physician’s choice in patients receiving chemotherapy for early stage breast cancer",
      authors: "Clemons M, Bouganim N, Smith S, G. Dranitsaris.",
      publication: "JAMA Oncol 2016;2:225-31",
      documentName: "JAMA_Oncol_Nov_2015.pdf",
    },
    {
      title:
        "Opiate Prescribing in Canada following the Legalization of Cannabis: A Clinical and Economic Time Series Analysis",
      authors:
        "G. Dranitsaris, C. DeAngelis, B. Pearson, L. McDermott, B. Pohlmann-Eden",
      publication: "Applied Health Economics and Health Policy",
      documentName: "Dranitsaris_time_series_2021.pdf",
    },
    {
      title:
        "Drug tendering: drug supply and shortage implications for the uptake of biosimilars",
      authors: " G. Dranitsaris, I. Jacobs, C. Kirchhoff, et al",
      publication: "Clinicoecon Outcomes Res 2017;9:573-584",
      documentName: "Dranitsaris_CEOR_Biosimilars__2017.pdf",
    },
    {
      title:
        "Correlation between progression-free survival and overall survival in metastatic breast cancer patients receiving anthracyclines, taxanes, or targeted therapies: a trial-level meta-analysis",
      authors: "G. Adunlin, J.W. Cyrus ,G. Dranitsaris",
      publication: "Breast Cancer Res Treat 2015;154:591-608",
      documentName: "PFS_OS_Breast_Can_Res_Treat_2015.pdf",
    },
    {
      title:
        "The development of a prediction tool to identify cancer patients at high risk for chemotherapy- induced nausea and vomiting",
      authors: "G. Dranitsaris, A. Molassiotis, M. Clemons, et al",
      publication: "Ann Oncol 2017;28:1260-67.",
      documentName: "Dranitsaris_Annal_Oncol_2017.pdf",
    },
    // {
    //     title:'Statistical Considerations in Clinical Trial Design of Immunotherapeutic Cancer Agents',
    //     authors:'The classical model for identification and clinical development of anticancer agents was based on small molecules, which were often quite toxic. Early studies in small groups of patients would seek to identify a maximum tolerated dose and major dose-limiting toxicities. Tumor response (shrinkage) would be assessed after a minimum number of doses in phase II testing. The decision to take the drug into the randomized phase III clinical setting was usually based on the proportion and duration of objective tumor responses, along with overall survival compared with historical controls. Immune-oncologics that are designed to fight cancer by direct CD8+ T-cell priming and activation or by blocking a negative regulatory molecule have a number of sharp distinctions from cytotoxic drugs. These include cytoreductive effects that may be very different in timing of onset from traditional chemotherapy and the potential for inducing long-term durable remissions even in heavily pretreated patients with metastatic dis- ease. In this paper we review the different classes of immune- oncologic drugs in clinical development with particular attention to the biostatistical challenges associated with evaluating efficacy in clinical trials. Confronting these issues upfront is particularly important given the rapidly expanding number of clinical trials with both monotherapy and combination immunooncology.',
    //     documentName:'Dranitsaris_JIT_2015.pdf'
    // },
    {
      title:
        "Dalteparin or vitamin K antagonists to prevent recurrent venous thromboembolism in cancer patients: a patient-level economic analysis for France and Austria",
      authors: "G. Dranitsaris, L.G. Shane, J.P. Galanaud, et al.",
      publication: "Support Care Cancer 2017;25:2093-2102",
      documentName: "Dranitsaris_SCC_Dalteparin_PE_2017.pdf",
    },
    {
      title:
        "Cost effectiveness vs. affordability in the age of immuno-oncology cancer drugs. ",
      authors: "G. Dranitsaris, X. Zhu, G. Adunlin, M.D. Vincent",
      publication: "Expert Rev Pharmacoecon Outcomes Res 2018;18:351-357.",
      documentName: "Exp_Rev_HEOR_Dranitsaris_2018.pdf",
    },
    {
      title:
        "Lanreotide vs. octreotide LAR for patients with advanced gastroenteropancreatic neuroendocrine tumors: An observational time and motion analysis.",
      authors: "P. Ryan, A. McBride, D. Ray , G Dranitsaris. ",
      publication: "J Oncol Pharm Pract 2019: 25:1425-1433",
      documentName: "T&M_Study_JOPP_2019.pdf",
    },
    // {
    //     title:'Interpreting results from oncology clinical trials: a comparison of denosumab to zoledronic acid for the prevention of skeletal-related events in cancer patients. Support Care Cancer 2012 Apr 27.',
    //     authors:'Applies the concept of number needed to treat towards the comparison of two drugs used to prevent skeletal related events in cancer patients.',
    //     documentName:'NNT_Paper_Dranitsaris_2012.PDF'
    // },
    // {
    //     title:'nab-Paclitaxel weekly or every 3 weeks compared to standard docetaxel as first-line therapy in patients with metastatic breast cancer: an economic analysis of a prospective randomized trial. Breast Cancer Res Treat 2010;119:717-24.',
    //     authors:'Uses resource use data collected during a prospective randomized trial to compare total cost of therapy between four treatments in patients with metastatic breast cancer.',
    //     documentName:'Dranitsaris_BCRT_2010.pdf'
    // }
  ],
  presentations: [
    {
      title:
        "Human Plasminogen Replacement Therapy vs. Best Supportive Care in Patients with Congenital Plasminogen Deficiency: A Comparison of Health Care Resource Use over a 48-Week Period.",
      authors:
        "G. Dranitsaris, Santamaria-Ortiz A, Dorward K, et al. Presented at the 60th annual meeting of the American Society of Hematology",
      documentName:
        "ASH_2018_Poster_Plasminogen_Therapy_&_Healthcare_Resource_Utilization_Dranitsaris.pdf",
    },
    {
      title:
        "Dalteparin Vs. Vitamin K Antagonists For The Prevention Of Recurrent Venous Thromboembolism In Patients With Cancer And Renal Impairment: A Pharmacoeconomic Analysis Of A Prospective Randomized Trial.",
      authors:
        "G. Dranitsaris , L.G. Shane , M. Crowther G. Feugère and S. Woodruff. Presented at the 21st annual meeting of the International Society of Pharmacoeconomics and Outcomes Research, Washington, DC.",
      documentName: "ISPOR_Fragmin_Poster03_10_2016.pdf",
    },
    {
      title:
        "A Systematic Review and Bayesian Network Meta Analysis (NMA) to Indirectly Compare the Efficacy of the Subsequent Entry Biologic (SEB) CT-P13 to REMICADE® (infliximab) in Patients with Crohn’s Disease (CD)",
      authors:
        "G. Dranitsaris. Feagan B. Presented at the 2016 annual meeting of the Canadian Society of Pharmaceutical Sciences.",
      documentName: "CSPS_Poster_04_28__Final2016.pdf",
    },
    {
      title:
        "Correlation between Progression Free Survival and Overall Survival in Metastatic Breast Cancer (MBC) Patients Receiving Anthracyclines, Taxanes or Targeted Therapies: A Trial Level Meta-Analysis.",
      authors:
        "G. Adunlin and G. Dranitsaris. Presented at the annual meeting of the American Society of Clinical Oncology, Chicago, Il",
      documentName: "ASCO_Poster_04_04_2016.pdf",
    },
    {
      title:
        "Abraxane® vs. Taxol® for patients with advanced breast cancer: A prospective time and motion analysis from a Chinese health care perspective. ",
      authors:
        "G. Dranitsaris, Y. Bo, L. Wang, et al. Presented at the 6th Asia-Pacific meeting of the International Society of Pharmacoeconomics and Outcomes Research.",
      documentName: "China_ISPOR_EU_Poster08_18_2014.pdf",
    },
    {
      title:
        "Long-Acting Muscarinic Antagonist in Chronic Obstructive Pulmonary Disease: A Rare Events Meta Analysis for Clinically Relevant Adverse Events",
      authors:
        "G. Dranitsaris, S. Schmitz, G. Adunlin, et al. Presented at the 16th annual meeting of the International Society of Pharmacoeconomics and Outcomes Research, Dublin, Ireland.",
      documentName: "ISPOR_COPD_Poster.pdf",
    },
  ],
};

module.exports.documents = documents;
