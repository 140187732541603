import React from "react";
import {
    Grid,
    GridList,
    Paper,
    Container,
    Typography,
} from "@material-ui/core";
import {
    makeStyles,
    useTheme,
    withStyles,
    withTheme,
} from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
    Link,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from "react-scroll";
import { relative } from "path";
// import backgroundImage from '../static/images/stock/pharma_light.jpg'
import backgroundImage from "../static/images/stock/AdobeStock_213506936.jpg";
// import sideImage from '../static/images/stock/sideline_pics/home.png';
import sideImage from "../static/images/stock/sideline_pics/AdobeStock_290247327.jpeg";
// let pharma = require('../static/images/stock/pharma.jpg')
const useStyles = makeStyles((theme) => ({
    homeContainer: {
        // height: "100vh",
        minHeight: "100vh",
        // maxHeight: "100vh",
        width: "100vw",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: 0,
        display: "block",
        overflow: "hidden",
    },
    mainContent: {
        paddingLeft: "10vw",
        paddingRight: "10vw",
    },
    contentContainer: {
        // justifyContent: "center",
        // alignItems: "center",
    },
    sideImageContainer: {
        overflow: "hidden",
        maxHeight: "100vh",
        marginRight: 0,
        marginLeft: 0,
        visibility: "hidden",
        minHeight: "100vh",
        [theme.breakpoints.up("md")]: {
            visibility: "visible",
        },
        [theme.breakpoints.up("sm")]: {
            background: `url(${sideImage})`,
            backgroundSize: "cover",
        },
    },
    headingText: {
        color: theme.palette.primary.headlineText,
        // paddingTop: 35,
        fontSize: "4rem",
        fontWeight: "bold",
        display: "block",
        marginBottom: "5vh",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem",
            marginTop: "2vh",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "2rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.2rem",
        },
    },
    welcomeText: {
        color: theme.palette.primary.headlineText,
        fontSize: "3rem",
        display: "block",
        marginTop: "20vh",
        [theme.breakpoints.down("xl")]: {
            fontSize: "2rem",
            marginTop: "10vh",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.5rem",
            marginTop: "10vh",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".85rem",
            marginTop: "10vh",
        },
    },
    containerText: {
        fontSize: "3rem",
        fontFamily: "Crimson Text",
        // marginLeft: "3vw",
        marginBottom: "1.4vh",
        display: "block",
        lineHeight: "3.5vh",
        textAlign: "justify",
        [theme.breakpoints.down("xl")]: {
            fontSize: "2rem",
            marginTop: "2vh",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.4rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".85rem",
        },
    },
}));

const Home = (props) => {
    const classes = useStyles();

    return (
        <Element name="home">
            <div className={classes.homeContainer}>
                <Grid container className={classes.contentContainer}>
                    <Grid item md={9} xs={12} className={classes.mainContent}>
                        <>
                            <Element className={classes.element}>
                                <span className={classes.welcomeText}>Welcome to</span>
                                <span className={classes.headingText}>
                                    Augmentium Pharma Consulting Inc.
                                </span>
                                <span
                                    //
                                    className={classes.containerText}
                                >
                                    Drug policy decision making is a complicated process. Factors
                                    that must be considered consist of disease epidemiology,
                                    patient need, drug safety, efficacy and impact on the pharmacy
                                    budget.
                                </span>
                                <span className={classes.containerText}>
                                    However, the consideration of drug cost alone can lead to
                                    erroneous health policy decision making. Other drug related
                                    characteristics such as reduced toxicity, improved
                                    effectiveness or reduced/avoided hospitalization can result in
                                    lower overall net costs.
                                </span>
                                <span className={classes.containerText}>
                                    The methodology of health economics and outcomes research
                                    considers all of these factors and provides data for informed
                                    decision making.
                                </span>
                                <span className={classes.containerText}>
                                    Augmentium Pharma Consulting Inc. is a Canadian based research
                                    consultancy offering such services to the pharmaceutical
                                    industry, hospitals and government agencies. By conducting our
                                    studies in a transparent manner using the most appropriate
                                    methodology, we can provide quality data to all of the key
                                    stakeholders.
                                </span>
                            </Element>
                        </>
                    </Grid>
                    <Grid item md={3} className={classes.sideImageContainer}>
                        {/* <Container></Container> */}
                    </Grid>
                </Grid>

                {/* </Paper> */}
            </div>
        </Element>
    );
};
export default withTheme(Home);
