import React from "react";
import { Grid, Container } from "@material-ui/core";
import { makeStyles, useTheme, withTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Element } from "react-scroll";
import { pdfjs } from "react-pdf";
import Documents from "../utils/documents";
import Modal from "react-modal";
import PdfSVG from "./pdfSVG";
// import backgroundImage from "../static/images/stock/stock-photo--library-160993595.jpg";
import backgroundImage from "../static/images/stock/susan-yin-2JIvboGLeho-unsplash.jpg";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
Modal.setAppElement("#root");
const useStyles = makeStyles((theme) => ({
    element: {
        // position: "relative",
        // left: "39%",
        width: "100vw",
        marginLeft: 0,
        marginRight: 0,
        minHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        width: "90%",
        paddingBottom: '5em',
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    headingText: {
        fontSize: "4rem",
        fontWeight: "bold",
        color: theme.palette.primary.headlineText,
        display: "block",
        marginBottom: "1.5vh",
        marginLeft: "1rem",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem",
            marginTop: '2em'
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "2rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.4rem",
            marginTop: "4rem",
        },
    },
    publicationsContainer: {
        // width: "80%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",

        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginLeft: "2.5%",
        },
    },

    publicationText: {
        fontSize: "3rem",
        fontFamily: "Crimson Text",
        marginBottom: "3vh",
        marginLeft: "1rem",
        display: "block",
        [theme.breakpoints.down("xl")]: {
            fontSize: "3rem"
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.2rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".85rem", lineHeight: '1rem'
        },
    },
    abstract: {
        fontStyle: "italic",
    },
    /* styles for '...' */

    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "auto",
        width: "20%",
        background: "#fff",
        marginRight: "1vw",
        marginBottom: "1vw",
        padding: "1vw",
        border: "1px solid grey",
        borderRadius: "1em",
        boxShadow: "1rem 1rem 2rem #bbb",
        position: "relative",
        [theme.breakpoints.down("lg")]: {
            height: "auto",
        },
        [theme.breakpoints.down("sm")]: {
            width: "90%",
            height: "auto",
            padding: "1rem",
            marginRight: "1rem",
            marginBottom: "1rem",
            hyphens: "auto",
        },
    },
    cardTitle: {
        //    height:'100px',
        textAlign: "left",
        fontStyle: "italic",
        textTransform: "capitalize",
        fontSize: "2rem",
        width: "90%",
        display: "block",
        [theme.breakpoints.down("xl")]: {
            fontSize: "1.5rem"
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: ".9rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".75rem",
            textAlign: "justify",
            width: "80%",
            marginBottom: "1.5rem"
            // marginTop: "3rem",
        },
        // paddingRight: "1rem",
    },

    cardAuthors: {
        //    height:'100px',
        textAlign: "left",
        fontSize: "1.5rem",

        [theme.breakpoints.down("xl")]: {
            fontSize: "1.5rem"
        },
        // position: "absolute",
        // bottom: "1rem",
        [theme.breakpoints.down("lg")]: {
            fontSize: ".8rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: ".75rem",
            // marginTop: "3rem",
        },
    },
    cardGrid: {
        height: '100%'
    },
    cardAuthorsGrid: {
        // height:'4vh'
        display: 'flex',
        alignItems: 'flex-end',
    },
    modal: {
        position: "absolute",
        top: 100,
        width: "auto%",
        left: "30%",
        height: "auto",
        background: "#fff",
        border: "1px solid #000",
        "&:focus": { outline: 0 },
    },
    link: {
        textDecoration: "none",
        color: "rgba(0, 0, 0, 0.54)",
        position: "relative",
        top: 10,
        display: "inline-block",
        borderRadius: "100%",
        width: "50px",
        height: "40px",
        backgroundColor: "#fff",
        lineHeight: "50px",
        textAlign: "center",
        verticalAlign: "center",
        paddingTop: "8px",
        "&:hover": {
            backgroundColor: "#ececec",
        },
    },
    pdfLink: {
        position: "absolute",
        top: ".5rem",
        right: ".5rem",
    },
}));
const Publications = (props) => {
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState(null);
    const classes = useStyles();
    const pdfSize = window.innerWidth < 600 ? "1.5rem" : "2vw";
    const documents = { ...Documents.documents };

    const loadSuccess = (document) => {
        const { numPages } = document;
        setNumPages(numPages);
        console.log(numPages);
    };

    const changePage = (offset) =>
        this.setState((prevState) => ({
            pageNumber: prevState.pageNumber + offset,
        }));
    const previousPage = () => this.changePage(-1);

    const nextPage = () => this.changePage(1);

    return (
        <Element name="publications" className={classes.element}>
            <div className={classes.container}>
                <span className={classes.headingText}>Publications</span>
                <span className={classes.publicationText}>
                    Whenever we engage in a research study, one of our main objectives has
                    always been to publish the results in professional journals.
                </span>
                <p id="anchor" />
                <div className={classes.publicationsContainer}>
                    {documents.publications.map((publication) => {
                        return (
                            <div key={publication.title} className={classes.card}>
                                <Grid container spacing={5} className={classes.cardGrid}>
                                    <Grid item xs={12} className={classes.cardTitleGrid}>
                                        <span
                                            variant="body1"
                                            component="p"
                                            className={classes.cardTitle}
                                        >
                                            {publication.title}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.cardAuthorsGrid}>
                                        <span
                                            color="textSecondary"
                                            component="p"
                                            className={classes.cardAuthors}
                                        >
                                            {publication.authors}
                                        </span>
                                    </Grid>
                                </Grid>
                                <div className={classes.pdfLink}>
                                    <a
                                        href={"/documents/publications/" + publication.documentName}
                                        title="download document"
                                        target="_blank"
                                    >
                                        {publication.documentName.length > 0 && (
                                            <PdfSVG height={pdfSize} width={pdfSize} />
                                        )}
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Element>
    );
};
export default withTheme(Publications);
