import React from "react";
import { Grid } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { Element } from "react-scroll";
import { makeStyles } from "@material-ui/core";
// import backgroundImage from "../static/images/stock/consultancy.jpg";
import backgroundImage from "../static/images/stock/green-chameleon-s9CC2SKySJM-unsplash.jpg";
import sideImage from "../static/images/stock/sideline_pics/AdobeStock_109320998.jpg";
import classnames from "classnames";
const useStyles = makeStyles((theme) => ({
    element: {
        height: "100vh",
        minHeight: "100vh",
        width: "100%",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: 0,
        display: "block",
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginLeft: "10px",
        },
    },
    contactContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    contactContainerText: {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
    },
    sideImageContainer: {
        marginTop: 0,
        overflow: "hidden",
        display: "none",
        minHeight: "100vh",
        [theme.breakpoints.up("md")]: {
            display: "block",
        },
        [theme.breakpoints.up("sm")]: {
            background: `url(${sideImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: -185,
        },
    },
    sideImageInnerContainer: {
        padding: 0,
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
        "& img": {
            width: "100%",
        },
    },
    contactText: {
        fontSize: "3rem",
        display: "block",
        marginBottom: "1vh",
        "& a": {
            textDecoration: "none",
            color: "#000",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.2rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
        },
    },
    headingText: {
        fontSize: "4rem",
        fontWeight: "bold",
        display: "block",
        color: theme.palette.primary.headlineText,
        marginBottom: "2vh",
        [theme.breakpoints.down("lg")]: {
            fontSize: "2rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.4rem",
            marginTop: "3rem",
        },
    },

    emphText: {
        fontWeight: "400 !important",
    },
}));
const Contact = (props) => {
    const classes = useStyles();
    return (
        <Element name="contact" className={classes.element}>
            <Grid container>
                <Grid item md={3} className={classes.sideImageContainer}>
                    {/* <Container className={classes.sideImageInnerContainer}>
              <img src={sideImage} />
            </Container> */}
                </Grid>
                <Grid item md={9} xs={12}>
                    <div className={classes.contactContainer}>
                        <div className={classes.contactContainerText}>
                            <span className={classes.headingText}>Contact Us</span>

                            <span
                                className={classnames(classes.contactText, classes.emphText)}
                            >
                                Augmentium Pharma Consulting Inc.
                            </span>
                            <span className={classes.contactText}>
                                455 Danforth Ave, Suite 448
                            </span>
                            <span className={classes.contactText}>Toronto ON M4K 1P1</span>
                            <span className={classes.contactText}>Tel: (416) 461-2720</span>
                            <span className={classes.contactText}>
                                Mobile: (647) 293 1644
                            </span>
                            <span className={classes.contactText}>
                                <a href="mailto:consulting@augmentium.com">
                                    consulting@augmentium.com
                                </a>
                            </span>
                            <span className={classes.contactText}>
                                <a href="/">http://www.augmentium.com</a>
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Element>
    );
};
export default withTheme(Contact);
