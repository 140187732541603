import React from "react";

const pdfSVG = (props) => {
  return (
    <svg
      style={{ enableBackground: "new 0 0 512 512" }}
      version="1.1"
      viewBox="0 0 512 512"
      //   xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
    >
      <g style={{ fill: "#E6110B" }} id="Layer_1" />
      <g id="Layer_3">
        <rect
          style={{ fill: "#E6110B" }}
          height="384"
          width="384"
          x="64"
          y="64"
        />
        <rect
          style={{ fill: "#210301" }}
          height="337.9"
          width="337.9"
          x="87"
          y="87"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M337.5,270.5c-15.5,0-35,2.7-41.3,3.7c-26.3-27.5-33.8-43.1-35.5-47.3c2.3-5.9,10.2-28.1,11.4-56.7   c0.6-14.3-2.5-25-9-31.8c-6.5-6.8-14.4-7.3-16.7-7.3c-7.9,0-21.2,4-21.2,30.9c0,23.3,10.9,48,13.9,54.4   c-15.8,46.1-32.8,77.6-36.4,84.1c-63.6,23.9-68.5,47.1-68.5,53.6c0,11.8,8.4,18.8,22.5,18.8c34.2,0,65.3-57.3,70.5-67.3   c24.3-9.7,56.7-15.7,65-17.1c23.7,22.6,51.1,28.6,62.4,28.6c8.6,0,28.6,0,28.6-20.6C383.1,277.3,358.5,270.5,337.5,270.5z    M335.9,284c18.4,0,23.3,6.1,23.3,9.3c0,2-0.8,8.6-10.7,8.6c-8.9,0-24.2-5.1-39.2-16.2C315.6,284.9,324.9,284,335.9,284z    M245.5,144.1c1.7,0,2.8,0.5,3.7,1.8c5.3,7.4,1,31.4-4.2,50.2c-5-16.1-8.8-40.9-3.5-49.6C242.5,144.9,243.7,144.1,245.5,144.1z    M236.5,288.2c6.7-13.5,14.2-33.2,18.2-44.3c8.2,13.7,19.1,26.3,25.5,33.2C260.5,281.3,245.5,285.5,236.5,288.2z M147.4,355.8   c-0.4-0.5-0.5-1.6-0.2-2.9c0.7-2.8,6-16.5,44.4-33.7c-5.5,8.7-14.1,21.1-23.6,30.3c-6.7,6.2-11.8,9.4-15.4,9.4   C151.4,358.9,149.7,358.5,147.4,355.8z"
          id="path"
        />
      </g>
    </svg>
  );
};

export default pdfSVG;
