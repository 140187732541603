// import Astra from '../static/images/cards/AstraZeneca.jpg'
// const path = 'http://' + window.location.hostname+':3000/static/images/cards/';

const clientData = {
    clients:[{
        name:'AstraZeneca',
        image:'AstraZeneca.jpg',
        link:'https://www.astrazeneca.com/',
        cardContent:'Astra Zeneca'
    },
    {
        name:'Bayer',
        image:'Bayer.jpg',
        link:'https://pharma.bayer.com/en/home/',
        cardContent:'Bayer Pharmaceutical '
    },
    {
        name:'boehringer-ingelheim',
        image:'boehringer-ingelheim.jpg',
        link:'https://www.boehringer-ingelheim.com/',
        cardContent:'Boehringer Ingelheim'
    },
    {
        name:'bristol-myers squibb',
        image:'squibb.PNG',
        link:'https://www.bms.com/',
        cardContent:'Bristol-Myers Squibb'
    },
    {
        name:'ipsen',
        image:'ipsen.png',
        link:'https://www.ipsen.com/',
        cardContent:'Ipsen Pharmaceutical'
    },{
        name:'lilly',
        image:'lilly.gif',
        link:'https://www.lilly.ca/en/index.aspx',
        cardContent:'Lilly'
    },{
        name:'gsk',
        image:'gsk.jpeg',
        link:'https://www.gsk.com/',
        cardContent:'GlaxoSmithKline'
    },
    {
        name:'novartis',
        image:'novartis.png',
        link:'https://www.novartis.com/',
        cardContent:'Novartis'
    },
    {
        name:'ogomerck',
        image:'merck-logo.png',
        link:'https://www.merck.com/index.html',
        cardContent:'Merck '
    },
    {
        name:'Janssen',
        image:'janssen.png',
        link:'https://www.janssen.com/',
        cardContent:'Janssen Pharmaceuticals'
    },
    {
        name:'pfizer',
        image:'pfizer.png',
        link:'pfizer.com',
        cardContent:'Phizer'
    },
    {
        name:'roche',
        image:'roche.png',
        link:'https://www.rochecanada.com/',
        cardContent:'Roche Canada'
    }
],
public:[
    {
        name:'CCO',
        image:'cco.jpeg',
        link:'cco.com',
        cardContent:'Cancer Care Ontario'
    },
    {
        name:'HealthCanada',
        image:'healthCanada.png',
        link:'hc.com',
        cardContent:'Health Canada'
    },
    {
        name:'NHS',
        image:'nhs.png',
        link:'nhs.co.uk',
        cardContent:'National Health System UK'
    },
    {
        name:'Sunny',
        image:'sunnybrook.png',
        link:'cco.com',
        cardContent:'Sunnybrook Odette Cancer Clinic'
    },
    {
        name:'UHN',
        image:'uhn.png',
        link:'uhn.com',
        cardContent:'UHN Ontario'
    },
    {
        name:'Ottawa Hospital',
        image:'ottawa_hospital.png',
        link:'https://www.ottawahospital.on.ca/',
        cardContent:'Ottawa Hospital'
    },
]
}

module.exports.clientData = clientData;