import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Dashboard from "./components/Dashboard";
const theme = createMuiTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    primary: {
      light: "#6ab7ff",
      main: "#1e88e5",
      dark: "#005cb2",
      contrastText: "#e1f5fe",
      headlineText: "#B3224B",
    },
    secondary: {
      light: "#5efc82",
      main: "#00c853",
      dark: "#009624",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      light: "#efefef",
    },
  },
  typography: {
    headingText: {
      fontWeight: 500,
      color: "#c62828",
      textAlign: "left",
      // margin: "0 auto",
      // marginTop: '15px'
    },
    categoryHeadingText: {
      fontWeight: 500,
      color: "#c62828",
      textAlign: "left",
      fontSize: "1.3em",
    },
    containerText: {
      fontWeight: 300,
      textAlign: "justify",
      margin: "10px auto",
    },
  },
  elements: {
    container: {
      height: "auto",
      minHeight: "100vh",
      width: "100%",
      marginTop: 200,
      padding: "0 20%",
      maxWidth: "none",
    },
    list: {
      listStyleType: "none",
      "& li": {
        textAlign: "left",
        marginBottom: 5,
        fontWeight: 300,
        fontFamily: "Montserrat",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      dxl: 2560,
      txl: 2840,
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Dashboard />
      </div>
    </MuiThemeProvider>
  );
}

export default App;
